

































































































































































import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import { Ref, ref, watch } from "@vue/composition-api";
import GroupsListFilters from "./GroupsListFilters.vue";
import GroupsHandler from "../groups-handler/GroupsHandler.vue";
import Ripple from "vue-ripple-directive";
import { Component, Vue, Watch } from "vue-property-decorator";
import useTable, { IFilterOptions, IRefreshTable } from "@core/utils/useTable";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";

// Services
import { useGroup, useAuth } from "@/services";

// Interfaces
import {
  IBodyListGroup,
  IResponseListGroup,
  TDataGroup,
} from "@core/services/interfaces/business/group/IGroupService";

@Component({
  name: "GroupsList",
  components: {
    GroupsListFilters,
    GroupsHandler,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    AvButton,
    AvExportButton,
    AvSkeletonTable,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
})
export default class GroupsList extends Vue {
  // Data
  $ENUMS = ENUMS;
  $refs = {
    refListTable: {} as any,
  };
  groupTitle = "Grupo Empresa";
  blankGroup = {
    id: undefined,
    name: "",
    code: null,
    status: null,
    economicGroupId: null,
    userBlockingId: null,
    descriptionBlocking: "",
  };
  group = ref(JSON.parse(JSON.stringify(this.blankGroup)));
  isGroupSidebarActive: boolean = false;
  groupId: number | null = null;
  statusOptions = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  statusFilter: Ref<boolean | null> = ref(null);
  codeFilter: Ref<string | null> = ref(null);

  tableColumns = [
    { key: "Codigo", label: "Código", sortable: true },
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Ativo", label: "Status", sortable: true },
    { key: "actions", label: "Ações" },
  ];

  useTable = new useTable(
    this.tableColumns,
    {
      sortBy: "Nome",
    },
    this.fetchListGroups
  );

  // Computeds
  get hasEconomicGroup(): boolean {
    const has =
      this.$store.state["appConfig"].params.EMP_ASSOCIACAO_GRUPO_ECONOMICO;

    return has ? (has == "S" ? true : false) : true;
  }

  // lifeCycle
  created() {
    if (this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE) {
      this.groupTitle =
        this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE;
    }

    if (useAuth.getDomain() == "Abracaf")
      this.tableColumns.splice(3, 0, {
        key: "GrupoEconomicoNome",
        label: "Grupo Econômico",
        sortable: true,
      });
  }

  // Watchs
  watchData = watch(
    [
      this.useTable.currentPage,
      this.useTable.perPage,
      this.useTable.searchQuery,
    ],
    () => {
      this.refreshData();
    }
  );

  @Watch("codeFilter.value")
  @Watch("statusFilter.value")
  refreshData() {
    this.$refs.refListTable.refresh();
  }

  // Methods
  /**
   * Essa função é acionada quando queremos abrir o modal de editar grupo
   * @param {Integer} groupId
   */
  fetchList(ctx: Vue, callback: Function) {
    this.useTable.fetchList(callback);
  }

  mapFilterToBody(options: IFilterOptions): IBodyListGroup {
    let body = this.useTable.mapFilterToBody(options);
    body.userdata = {};

    if (this.codeFilter.value && this.codeFilter.value != "")
      body.userdata.Codigo = this.codeFilter.value;

    if (this.statusFilter.value != null)
      body.userdata.Ativo = this.statusFilter.value;

    return body;
  }

  async fetchListGroups(options: IFilterOptions): Promise<IRefreshTable> {
    return useGroup
      .requestListGroup(this.mapFilterToBody(options))
      .then((response: IResponseListGroup) => {
        const data = response.data;

        return {
          data: data.data,
          total: data.recordsTotal,
          display: data.recordsFiltered,
        };
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao buscar a lista de Grupos Econômicos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return {
          data: [],
          total: 0,
          display: 0,
        };
      });
  }

  handlerSidebarGroup(groupId: number | null) {
    this.isGroupSidebarActive = true;
    this.groupId = groupId;
  }

  getGroups() {
    return new Promise((resolve, reject) => {
      let body = this.mapFilterToBody({
        perPage: this.useTable.perPage.value,
        currentPage: this.useTable.currentPage.value,
        searchQuery: this.useTable.searchQuery.value,
        sortyBy: this.useTable.sortBy.value,
        isSortDirDesc: this.useTable.isSortDirDesc.value,
        filters: this.useTable.filters,
      });

      body.length = 500000;

      useGroup
        .requestListGroup(body)
        .then((response: IResponseListGroup) => {
          const data = response.data.data.map((group: TDataGroup) => {
            let data = {};

            if (this.hasEconomicGroup) {
              data = {
                Codigo: group.Codigo,
                Nome: group.Nome,
                Status: group.Ativo ? "Ativo" : "Inativo",
                "Grupo Econômico": group.GrupoEconomicoNome,
              };
            } else {
              data = {
                Codigo: group.Codigo,
                Nome: group.Nome,
                Status: group.Ativo ? "Ativo" : "Inativo",
              };
            }

            return data;
          });

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
